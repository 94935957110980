interface CommentaryAnalysisBasicInfoState {
  company: string
  department: string
  instructorCompany: string
  instructorDepartment: string
  instructorCode: string
  instructorName: string
}

const initialState: CommentaryAnalysisBasicInfoState = {
  company: '',
  department: '',
  instructorCompany: '',
  instructorDepartment: '',
  instructorCode: '',
  instructorName: '',
}

const state = {
  commentaryAnalysisBasicInfo: initialState
}

const getters = {
  commentaryAnalysisBasicInfo: (state: any) => state.commentaryAnalysisBasicInfo
}

const mutations = {
  set: (state: any, payload: CommentaryAnalysisBasicInfoState) => {
    state.commentaryAnalysisBasicInfo = payload
  },
  init: (state: any) => (
    state.commentaryAnalysisBasicInfo = initialState
  )
}

const actions = {
  cacheCommentaryAnalysisBasicInfo({ commit }: any, commentaryAnalysisBasicInfo: CommentaryAnalysisBasicInfoState) {
    sessionStorage.setItem('commentary-analysis-basic-info', JSON.stringify(commentaryAnalysisBasicInfo))
    commit('set', commentaryAnalysisBasicInfo)
  },
  loadCommentaryAnalysisBasicInfoFromCache({ commit }: any) {
    const item = sessionStorage.getItem('commentary-analysis-basic-info')
    if (item) {
      commit('set', JSON.parse(item))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}