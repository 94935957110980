interface CommentaryAnalysisSettingState {
  cropRect: number[]
}

const initialState: CommentaryAnalysisSettingState = {
  cropRect: [10, 10, 10, 10]
}

const state = {
  commentaryAnalysisSetting: initialState
}

const getters = {
  commentaryAnalysisSetting: (state: any) => state.commentaryAnalysisSetting
}

const mutations = {
  set: (state: any, payload: CommentaryAnalysisSettingState) => {
    state.commentaryAnalysisSetting = payload
  },
  init: (state: any) => (
    state.commentaryAnalysisSetting = initialState
  )
}

const actions = {
  loadCommentaryAnalysisSettingFromCache({ commit }: any) {
    const item = sessionStorage.getItem('commentary-analysis-setting')
    if (item) {
      const setting = JSON.parse(item)
      setting.cropRect = setting.cropRect.map((s: string) => parseInt(s, 10));
      commit('set', setting)
    }
  },
  cacheCommentaryAnalysisSetting({ commit }: any, commentaryAnalysisSetting: CommentaryAnalysisSettingState) {
    sessionStorage.setItem('commentary-analysis-setting', JSON.stringify(commentaryAnalysisSetting))
    commit('set', commentaryAnalysisSetting)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}