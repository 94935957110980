import Vue from 'vue'
import Vuex from 'vuex'
import CommentaryAnalysisModule from './backend/commentaryAnalysis'
import CommentaryAnalysisHistoriesModule from './firestone/commentaryAnalysisHistories'
import StorageProfilePhotoModule from '../store/storage/profilePhoto'
import UiAppModule from '../store/ui/app'
import CommentaryAnalysisBasicInfoModule from '../store/ui/commentaryAnalysisBasicInfo'
import CommentaryAnalysisSettingModule from '../store/ui/commentaryAnalysisSetting'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    CommentaryAnalysisModule: CommentaryAnalysisModule,
    CommentaryAnalysisHistoriesModule: CommentaryAnalysisHistoriesModule,
    StorageProfilePhotoModule: StorageProfilePhotoModule,
    UiAppModule: UiAppModule,
    CommentaryAnalysisBasicInfoModule: CommentaryAnalysisBasicInfoModule,
    CommentaryAnalysisSettingModule: CommentaryAnalysisSettingModule,
  }
})
