interface UiAppState {
  isAdmin: boolean
}

const initialState: UiAppState = {
  isAdmin: false
}

const state = {
  uiAppState: initialState
}

const getters = {
  uiApp: (state: any) => state.uiAppState
}

const mutations = {
  setIsAdmin: (state: any, isAdmin: boolean) => (
    state.uiAppState.isAdmin = isAdmin
  ),
  init: (state: any) => (
    state.uiAppState = initialState
  )
}

const actions = {
  cacheIsAdmin({ commit }: any, isAdmin: boolean) {
    sessionStorage.setItem('is-admin', JSON.stringify(isAdmin))
    commit('setIsAdmin', isAdmin)
  },
  loadIsAdminCache({ commit }: any) {
    const item = sessionStorage.getItem('is-admin')
    if (item) {
      commit('setIsAdmin', JSON.parse(item))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}