import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import firebase from '../firebase'
import { routerNames } from '@/types/routerNames';

Vue.use(VueRouter)

  const routes: Array<RouteConfig> = [{
    path: '/login',
    name: routerNames.login,
    component: () => import('../views/Login.vue')
  }, {
    path: '/',
    name: routerNames.home,
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/commentary-analysis',
    name: routerNames.commentaryAnalysis,
    component: () => import('../views/CommentaryAnalysis.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '*',
    name: routerNames.notFound,
    component: () => import('../views/NotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(recode => recode.meta.requiresAuth)) {
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        next({
          query: {
            redirect: to.fullPath
          },
          name: routerNames.login
        })
      } else {
        next()
      }
    })
  } else if (to.matched.some(recode => recode.meta.requiresAdmin)) {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || !JSON.parse(process.env.VUE_APP_ADMIN_EMAIL).includes(user.email)) {
        next({
          name: routerNames.login
        })
      } else {
        next()
      }
    })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (from.path !== '/' && to.path === '/' && from.path !== to.path) {
    //
  }
})

export default router
