import { CommentaryAnalysisResult } from '@/types/commentaryAnalysisResult';
import { DateTime } from 'luxon';

const state = {
  commentaryAnalysisResultState: null,
  commentaryAnalysisResultHttpStatus: null,
  commentaryReview: null,
};

const getters = {
  commentaryAnalysisResult: (state: any) => state.commentaryAnalysisResultState,
  commentaryAnalysisResultHttpStatus: (state: any) => state.commentaryAnalysisResultHttpStatus,
  commentaryReview: (state: any) => state.commentaryReview,
  commentaryAnalysisResultAggregate: (state: any) => {
    const commentaries: any = {};
    state.commentaryAnalysisResultState.commentaries.forEach((c: any) => {
      // TODO 一時的にコメンタリーのないシーンはカウントしない
      if (!c.text) {
        return;
      }
      if (c.kind in commentaries) {
        if (c.commented) {
          commentaries[c.kind].commented += 1;
        } else {
          commentaries[c.kind].notcommented += 1;
        }
      } else {
        commentaries[c.kind] = {
          commented: 0,
          notcommented: 0,
        };
        if (c.commented) {
          commentaries[c.kind].commented = 1;
        } else {
          commentaries[c.kind].notcommented = 1;
        }
      }
    });
    return commentaries;
  },
};

const mutations = {
  init: (state: any) => (state.commentaryAnalysisResultState = null),
  add: (state: any, payload: CommentaryAnalysisResult) =>
    (state.commentaryAnalysisResultState = payload),
  addHttpStatus: (state: any, httpStatus: number) =>
    (state.commentaryAnalysisResultHttpStatus = httpStatus),
  initGuidancePointComment: (state: any, payload: any) =>
    (state.commentaryReview = payload),
};

const actions = {
  async getCommentaryAnalysisResult({ commit }: any, pid: string) {
    if (!pid) {
      return;
    }
    return await fetch(
      process.env.VUE_APP_BACKEND_ENDPOINT +
      '/commentary-analysis/result?pid=' +
      pid
     )
    .then((response) => {
      const status = response.status;
      return response.json()
      .then((data) => {
        commit('addHttpStatus', status);
        if (status !== 200) {
          commit('init');
          return;
        }
        data.analyzed_at = DateTime.fromISO(data.analyzed_at, {
          zone: 'utc',
        }).toLocal();
        commit('add', data);
      });
    })
    .catch((error) => {
      commit('init');
      console.error(error);
    });
  },
  async analyzeCommentaryVideo(_: any, options: {video: File, cropRect: number}): Promise<string> {
    const response_register_signed_url = await fetch(
      process.env.VUE_APP_BACKEND_ENDPOINT + '/commentary-analysis/generate-upload-signed-url');

    try {
      const content_register = await response_register_signed_url.json();
      if (response_register_signed_url.status !== 200) return ''

      const formData = new FormData();
      formData.append('file', options.video);
      const response_upload_file = await fetch(
        content_register.upload_signed_url,
        {
          method: 'POST',
          body: formData
        }
      );
      if (response_upload_file.status !== 204) return ''

      const postformData = new FormData();
      postformData.append('pid', content_register.pid)
      postformData.append('crop_rect', options.cropRect.toString())
      const response = await fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT +
          '/commentary-analysis/create-analysing-task',
        {
          method: 'POST',
          body: postformData
        }
      );
      const content_post = await response.json();

      return response.status === 200 ? content_post.pid : ''
    } catch (e) {
      return '';
    }
  },
  async updateGuidancePointComment({ commit, state }: any, val: any) {
    const data = [...state.commentaryReview];
    data[val.index] = val.content;
    commit('initGuidancePointComment', data);
  },
  async initGuidancePointComment({ commit, state }: any) {
    const commentaryReview: any = [];
    state.commentaryAnalysisResultState.commentaries.forEach(() => {
      commentaryReview.push({ input1: '', input2: '', input3: 0, input4: '', input5: '' });
    });
    commit('initGuidancePointComment', commentaryReview);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
