import firebase from '@/firebase';
import CommentaryAnalysisHistoryState from '@/types/commentaryAnalysisHistory';

const db = firebase.firestore();

const state = {
  commentaryAnalysisHistories: [],
  commentaryAnalysisDetail: {},
};

const getters = {
  commentaryAnalysisHistories: (state: any) => state.commentaryAnalysisHistories,
  commentaryAnalysisDetail: (state: any) => state.commentaryAnalysisDetail,
};

const mutations = {
  init: (state: any) => {
    state.commentaryAnalysisHistories = [];
  },
  set: (state: any, payload: CommentaryAnalysisHistoryState) => {
    state.commentaryAnalysisHistories.push(payload);
  },
  setDetail: (state: any, payload: CommentaryAnalysisHistoryState) => {
    state.commentaryAnalysisDetail = payload;
  },
  add: (state: any, payload: CommentaryAnalysisHistoryState) => {
    const index = state.commentaryAnalysisHistories.findIndex(
      (history: CommentaryAnalysisHistoryState) => history.pid === payload.pid
    );

    if (index === -1) {
      state.commentaryAnalysisHistories.push(payload);
    } else {
      if (state.commentaryAnalysisHistories[index].status !== payload.status) {
        state.commentaryAnalysisHistories[index] = payload;
      }
    }
  },
  update: (state: any, payload: CommentaryAnalysisHistoryState) => {
    const index = state.commentaryAnalysisHistories.findIndex(
      (history: CommentaryAnalysisHistoryState) => history.pid === payload.pid
    );
    state.commentaryAnalysisHistories[index] = payload;
  },
  // remove: (state: any, payload: pid) => {
  //   remove item from state commentaryAnalysisHistories
  // },
};

const actions = {
  async listCommentaryAnalysisHistory({ commit }: any) {
    commit('init');
    return await db
      .collection('commentaryAnalysisHistories')
      .get()
      .then((doc) => {
        doc.docs.forEach((d) => {
          const data = d.data();
          if (data.deleteAt){
            return;
          }
          data.ref = d.ref;
          data.id = d.id;
          data.createdAt = data.createdAt.toDate();
          data.updatedAt = data.updatedAt.toDate();
          commit('set', data);
        });
        return true;
      })
      .catch(() => {
        commit('init');
        return false;
      });
  },
  async watchCommentaryAnalysisHistory({ commit }: any) {
    return await db.collection('commentaryAnalysisHistories').onSnapshot(
      (doc) => {
        doc.docChanges().forEach((change) => {
          const data = change.doc.data();
          data.ref = change.doc.ref;
          data.id = change.doc.id;
          if (!data.updatedAt && !data.updatedAt) {
            data.createdAt = new Date();
            data.updatedAt = new Date();
          } else {
            data.createdAt = data.createdAt.toDate();
            data.updatedAt = data.updatedAt.toDate();
          }
          if (change.type === 'added') {
            commit('add', data);
          }
          if (change.type === 'modified') {
            commit('update', data);
          }
          if (change.type === 'removed') {
            console.log('removed is not implement yet');
            // commit('remove', data)
          }
        });
      },
      (error) => {
        commit('init');
        console.log(error);
        return false;
      }
    );
  },
  async getCommentaryDetail(
    { commit }: any,
    id: string
  ){
    if (!id) return ;
    return await db
    .collection('commentaryAnalysisHistories')
    .where('pid', '==', id)
    .get()
    .then((doc) => {
      if (doc.docs.length === 0) {
        return false;
      }
      commit('setDetail', doc.docs[0].data());
      return true;
    })
    .catch(() => {
      return false;
    });
  },
  async getCommentaryAnalysisHistory(
    { commit }: any,
    payload: CommentaryAnalysisHistoryState
  ) {
    if (!payload.pid) return;

    return await db
      .collection('commentaryAnalysisHistories')
      .where('pid', '==', payload.pid)
      .get()
      .then((doc) => {
        if (doc.docs.length === 0) {
          return false;
        }
        const data = doc.docs[0].data();
        data.ref = doc.docs[0].ref;
        data.id = doc.docs[0].id;
        data.createdAt = data.createdAt.toDate();
        data.updatedAt = data.updatedAt.toDate();
        if (!data.imageDisplayConditions) {
          data.imageDisplayConditions = [{ isShow: true, time: '' }];
        }
        commit('set', data);
        return true;
      })
      .catch(() => {
        commit('init');
        return false;
      });
  },
  async createCommentaryAnalysisHistory(
    { commit }: any,
    payload: CommentaryAnalysisHistoryState
  ) {
    payload.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    payload.status = 'New';
    return await db
      .collection('commentaryAnalysisHistories')
      .add(payload)
      .then((doc) => {
        payload.ref = doc;
        commit('set', payload);
        return payload;
      })
      .catch(() => {
        commit('init');
        return null;
      });
  },
  async updateCommentaryAnalysisHistory(_: any, payload: any) {
    if (!payload.pid) return;
    return await db
      .collection('commentaryAnalysisHistories')
      .doc(payload.id)
      .update({ imageDisplayConditions: payload.imageDisplayConditions })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async updateCommentaryAnalysisDetail(_: any, payload: any){
    if (!payload.id) return;
    return await db
      .collection('commentaryAnalysisHistories')
      .doc(payload.id)
      .update({
        company: payload.company,
        department: payload.department,
        driverCode: payload.driverCode,
        driverName: payload.driverName,
        instructorCompany: payload.instructorCompany,
        instructorDepartment: payload.instructorDepartment,
        instructorCode: payload.instructorCode,
        instructorName: payload.instructorName,
        recordedDate: payload.recordedDate,
        recordedTime: payload.recordedTime,
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async deleteCommentaryAnalysis(_: any, id:string){
    if (!id) return;
    return await db
    .collection('commentaryAnalysisHistories')
    .doc(id)
    .update({deleteAt:new Date().toString()})
    .then(() => {
      return true;
    })
    .catch((err) => {
        return Promise.reject(err);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
