import { ja } from 'vuetify/src/locale';

export default {
  ...ja,

  app: {
    title: '運転診断AI',
  },
  commentaryAnalysis: {
    title: 'コメンタリー映像解析',
    result: '運転診断',
    report: '運転診断書',
  },
};
